.datepicker--days-names{
	color: #9DCCDE !important;
}
.datepicker--cell{
	&.-current-{
		color: #4DBEEB !important;
	}
}
.datepicker--cell.-selected-, .datepicker--cell.-selected-.-current-{
	background: #4DBEEB !important;
}