@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  40% {
    -moz-transform: translateY(-6px);
    -ms-transform: translateY(-6px);
    -webkit-transform: translateY(-6px);
    transform: translateY(-6px);
  }
  60% {
    -moz-transform: translateY(-3px);
    -ms-transform: translateY(-3px);
    -webkit-transform: translateY(-3px);
    transform: translateY(-3px);
  }
}

@keyframes criss-cross-left {
  0% {
    left: -20px;
  }
  50% {
    left: 50%;
    @include size(20px);
  }
  100% {
    left: 50%;
    @include size(250px*1.5);
  }
}

@keyframes criss-cross-right {
  0% {
    right: -20px;
  }
  50% {
    right: 50%;
    @include size(20px);
  }
  100% {
    right: 50%;
    @include size(250px*1.5);
  }
}

@keyframes slideInTop{
  0%{transform: translateY(-10px);}
  100%{transform: translateY(0px);}
}
@keyframes slideInBottom{
  0%{transform: translateY(10px);}
  100%{transform: translateY(0px);}
}


.bounce{
 animation: bounce 2s infinite;
}
