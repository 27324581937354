.header{
	background-color: #9DCCDE;
	color:#fff;
	padding: 18px 0;
	min-height: 110px;
	.header__top{margin-bottom: 5px;}
	.header__bottom{
		.order__id{
			h5{letter-spacing: 0.4px;}
		}
		.last__edit,
		.request__date{
			h6{display: inline-block;}
			.label{
				opacity: 0.7;
			}
		}
	}
}