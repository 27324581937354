input[type='submit'],
button{ cursor:pointer; }
a,
input[type='submit'],
button{
    position:relative;
    display: inline-block;
    border: none; outline: none; 
    text-decoration:none;
    transition: .3s ease;
    &.btn--primary{
        font-size: 14px;
        color: #FFFFFF;
        letter-spacing: 0;
        background-color: #4DBEEB;
        border-radius: 6px;
        padding: 6px 15px 7px;
        transition: .3s ease;
        &:hover{
            background-color: darken(#4DBEEB, 5%);
        }
    }
    &.btn--primary2{
        font-size: 14px;
        color: #87AEBD;
        letter-spacing: 0;
        background-color: #FFFFFF;
        box-shadow: 0 0 4px 0 rgba(0,0,0,0.08), 0 2px 4px 0 rgba(0,0,0,0.12);
        border-radius: 6px;
        padding: 6px 15px;
        transition: .3s ease;
        &:hover{
            background-color: darken(#FFFFFF, 5%);
        }
    }
    &.btn--pasive{
        font-size: 14px;
        color: #464646;
        letter-spacing: 0;
        background: #F1F0F5;
        border-radius: 6px;
        padding: 6px 15px;
        transition: .3s ease;
        &:hover{
            background-color: darken(#F1F0F5, 5%);
        }
    }
}