.notification{
	text-align: center;
	background-color: #7D7D85;
	overflow: hidden;
	transition: max-height .3s ease;
	max-height: 300px;
	&.hidden{
		max-height: 0;
	}
	&.success{
		background-color: #457E94;
		p{
			position: relative;
			&:before{
				content: '';
				background-image: url(/images/ico/ico-success.svg);
				background-repeat: no-repeat;
				background-position: center;
				width:16px;height:16px;
				position: absolute;
				top:0;bottom:0;left:-22px;
				margin: auto;
			}
		}
	}
	p{
		padding: 13px 0px;
		font-size: 14px;
		color: #FFFFFF;
		letter-spacing: 0;
		display: inline-block;
	}
}

.notifyjs-toastr-msg-error{
	.toastr__box{
		.toastr__icon{background-color: #DE6060;}
		.toastr__content__ul{
			.toastr__content__li{color: #DE6060;}
		}
	}	
}
.toastr__box{
	width: 420px;
	position: relative;
	display: -ms-flexbox;
	display: flex;
	box-shadow: 0 2px 4px 0 rgba(0,0,0,.12);
	.toastr__icon{
		background-color: #457E94;
		position: relative;
		width: 49px;
		border-top-left-radius: 3px;
		border-bottom-left-radius: 3px;
		display: flex;
		align-items: center;
		justify-content: center;
		.img{
			width: 20px;
			height: 21px;
			background-image: url(/images/ico/ico-important.svg);
			background-repeat: no-repeat;
			background-position: center;
			background-size: 20px;
		}
	}
	.toastr__msg{
		background-color: #fff;
		border-top-right-radius: 3px;
		border-bottom-right-radius: 3px;
		padding: 20px 19px 10px 18px;
		width: calc(100% - 49px);
	}
	.toastr__close{
		border: none;
		background-color: transparent;
		padding: 0;
		font-size: 28px;
		color: rgba(66, 66, 66, 0.5);
		width: 12px;
		line-height: 0;
		position: absolute;
		height: 12px;
		right: 24px;
		top: 24px;
		&:hover{
			color: #424242;
		}
	}
	.toastr__title{
		color: #DE6060;
		margin: 5px 0;
		font-size: 21px;
	}
	.toastr__content{
		font-size: 15px;
		color: #424242;
		margin: 7px 19px 7px 0;
	}
	.toastr__content__ul{
		overflow-y: auto;
		max-height: 200px;
		overflow-x: hidden;
		word-break: break-word;
		.toastr__content__li{
			letter-spacing: 0.3px;
			list-style-type: disc;
			margin-left: 18px;
			color: #457E94;
			.text{
				font-size: 12px;
				color: #627F8F;
			}
		}
	}
}