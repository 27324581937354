.section{
	&.accordion{
		padding-bottom: 32px;
		border-bottom: 4px dotted #E2E2E9;
		margin-bottom: 32px;
		.title{margin-bottom: 20px;color: #172B4D;padding-right: 35px;}
		.row-gutter{
			.col{
				border-top: 1px solid rgba(226,226,233,0.50);
				padding: 12px 0;
				overflow: hidden;
				label{
					margin-bottom: 5px;
				}
				h5{
					width: 100%;
					text-overflow: ellipsis;
					overflow: hidden;
				}
			}
		}
		.btn--toggle{
			position: absolute;
			right: 0;
			.ico-chevrondown{transition: .3s ease;}
			&.expand{
				.ico-chevrondown{
					transform: rotate(-180deg);
				}
			}
		}
		.accordion__content{
			max-height: 73px;
			overflow: hidden;
			transition: .3s linear;
			&.active{
				max-height: 200px;
			}
		}
	}

	&.order{
		color: #555563;
		.title{margin-bottom: 20px;color: #172B4D;}
		.order__filter{
			margin-bottom: 24px;
			.col{
				.chosen-container{
					width: 100%;
				}
			}
		}
		.order__detail{
			.original__instruction{
				margin-bottom: 10px;
			}
			.original__order{
				padding: 8px 15px;
				background-color: rgba(235, 237, 240, .3);
				border-radius: 6px;
				margin-bottom: 24px;
				.table-content{
					padding: 4px 0;
				}
			}
			.modify__order{
				background: #FFFFFF;
				border: 1px solid #E2E2E9;
				box-shadow: 0 0 3px 0 rgba(0,0,0,0.12);
				border-radius: 6px;	
				padding: 8px 15px;
				margin-bottom: 24px;
				ul.table-content{
					li.table-content__li{
						padding: 15px 0;
						border-bottom: 2px dashed #E2E2E9;
						// &:last-child{
						// 	border-bottom:0;
						// }
					}
					.table-row{
						padding: 0 0 8px;
					}
				}
				.add-more-item{
					font-size: 12px;
					color: #4DBEEB;
					letter-spacing: 0;
					margin: 15px 0;
					text-decoration: none;
					&:hover{
						text-decoration: underline;
					}
				}
				.checkbox__container{
					margin-top: 14px;
				}
			}
			.modify__instruction{margin-bottom: 10px;}
		}	

		.tag{
			font-size: 12px;
			color: #FFFFFF;
			letter-spacing: 0;
			border-radius: 4px;
			text-align: center;
			padding: 5px 11px 6px;
			display: inline-block;
			&.edit{
				background-color: #FFAB22;
				&:before{
					content:'Edited';
				}
			}
			&.new{
				background-color: #4DBEEB;
				&:before{
					content: 'New';
				}
			}
		}

		.reset__button{
			font-size: 12px;
			color: #FF233E;
			letter-spacing: 0;
			padding: 0;
			text-decoration: none;
			&:hover{
				text-decoration: underline;
			}
		}

		.box__mini-text{
			font-size: 12px;
			color: #555563;
			letter-spacing: 0;
			background: #F9F9FA;
			border-radius: 4px;
			padding: 3px 10px;
			min-height: 30px;
		}

		.table{
			h6{font-size: 16px;margin:0;}
			.table-row{
				@include clearfix;
				padding: 10px 0;
				.table-col{
					float: left;
					min-height: 1px;
					margin-right: gutter(of 12);
					&:last-child{
						margin-right: 0;
					}
					&.m1{width: susy-span(1 narrow of 12 narrow);}
					&.m2{width: susy-span(2 narrow of 12 narrow);}
					&.m3{width: susy-span(3 narrow of 12 narrow);}
					&.m4{width: susy-span(4 narrow of 12 narrow);}
					&.m5{width: susy-span(5 narrow of 12 narrow);}
					&.m6{width: susy-span(6 narrow of 12 narrow);}
					&.m7{width: susy-span(7 narrow of 12 narrow);}
					&.m8{width: susy-span(8 narrow of 12 narrow);}
					&.m9{width: susy-span(9 narrow of 12 narrow);}
					&.m10{width: susy-span(10 narrow of 12 narrow);}
					&.m11{width: susy-span(11 narrow of 12 narrow);}
					&.m12{width: susy-span(12 narrow of 12 narrow);}
				}
			}
			.table-content{
				.amount{
					color:rgba(85, 85, 99, .5);
					margin-bottom: 4px;
					display: block;
				}
				.additional-text{
					font-size: 12px;
					color: #19B657;
					letter-spacing: 0;
					margin-top: 4px;
					display: block;
				}
			}

			.head{
				letter-spacing: 0.4px;
				color: #AFAFBD;
			}
		}
		.form-input{
			margin-bottom: 16px;
		}
		.disabled{
			pointer-events: none;
			opacity: .55;
		}
	}
}

.copy-link-section{
	position: relative;
	.copy-link-button{
		font-family: 'opensans-semibold';
		font-size: 14px;
		color: #87AEBD;
		letter-spacing: 0;
		background-color: #FFFFFF;
		box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.08), 0 2px 4px 0 rgba(0, 0, 0, 0.12);
		border-radius: 6px;
		padding: 6px 15px;
		transition: .3s ease;
	}
	.copy-link-name{
		position: relative;
		z-index: 3;
		.copy-link-button{
			padding: 6px 40px 6px 15px;
			&:hover{
				background-color: darken(#fff, 5%);
			}
			&:active{
				background-color: darken(#fff, 10%);	
			}
		}
	}
	.button-trigger-dropdown{
		height: 31px;
		width: 31px;
		background-color: #fff;
		border-left: 1px solid #E2E2E9;
		position: absolute;
		right: 0;
		top:0;
		border-radius: 0 6px 6px 0;
		cursor: pointer;
		transition: .3s ease;
		&:after{
			content: '';
			width: 7px;
			height: 7px;
			border-top: 1px solid #5B5B6F;
			border-left: 1px solid #5B5B6F;
			position: absolute;
			top:0;left:0;right:0;bottom:0;
			margin:auto;
			transform: rotate(-135deg);
		}
		&:hover{
			background-color: darken(#fff, 10%);
		}
	}
	.copy-link-dropdown{
		display: none;

		position: absolute;
		width: 100%;
		z-index: 3;
		margin: 0;
		li{
			margin-top: 5px;
		}
		&.active{
			display: block;
		}
	}
}

body{
	&.show-copy-link-dropdown{
		overflow: hidden;;
		&:after{
			content: '';
			width: 100%;
			height: 100%;
			background-color: rgba(#000, .5);
			position: absolute;
			top:0;left:0;
		}
	}
}