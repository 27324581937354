.indent-left {
    padding-left: 30px;
    width: 74.6%;
    margin: 7px 0;
    .table-col {
    margin-bottom: 10px;
        .chosen-container:not(:last-child) {
            margin-bottom: 7px;
        }
        .items-title {
            font-family: 'opensans-bold';
            font-size: 13px!important;
            margin-bottom: 5px!important;
            
        }
    }
    .table-row.mb-0 {
        .table-col {
            margin-bottom: 2px!important;
        }
    }
}

.checkbox-blue-checklist {
    display: block;
    position: relative;
    padding-left: 28px;
    cursor: pointer;
    font-size: 12px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    color: black;
    input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
        &:checked ~ .checked {
            background-color: #2196F3;
        }
        &:checked ~ .checkmark:after {
            display: block;
        }
    }
    .checkmark  {
        position: absolute;
        top: 0;
        left: 0;
        height: 20px;
        width: 20px;
        background-color: #ebedf0;
        border-radius: 5px;
        &:after {
            content: "";
            position: absolute;
            display: none;
            left: 7px;
            top: 2px;
            width: 7px;
            height: 15px;
            border: solid #4dbeeb;
            border-width: 0 3px 4px 0;
            -webkit-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            transform: rotate(45deg);
        }
    }
}

.custom-grid-5 {
    width: 18.6%!important;
}

.note-ico {
    width: 20px;
    height: 20px;
}

.mr-1 {
    margin-right: 1%!important;
}

.w-custom {
    width: 71.4%!important;
}

.disabled-input {
    opacity: .5;
}