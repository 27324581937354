//Media Query
@mixin break ($point) {
  @if $point == 1920 {
    @media (max-width: 1920px) { @content; }
  }
  @else if $point == 1900 {
    @media (max-width: 1900px) { @content; }
  }
  @else if $point == 1600 {
    @media (max-width: 1600px) { @content; }
  }
  @else if $point == 1425 {
    @media (max-width: 1425px) { @content; }
  }
  @else if $point == 1360 {
    @media (max-width: 1360px) { @content; }
  }
  @else if $point == 1300 {
    @media (max-width: 1300px) { @content; }
  }
  @else if $point == 1200 {
    @media (max-width: 1200px) { @content; }
  }
  @else if $point == 1024 {
    @media (max-width: 1024px) { @content; }
  }
  @else if $point == 980 {
    @media (max-width: 980px) { @content; }
  }
  @else if $point == 800 {
    @media (max-width: 800px)  { @content; }
  }
  @else if $point == 768 {
    @media (max-width: 768px)  { @content; }
  }
  @else if $point == 600 {
    @media (max-width: 600px)  { @content; }
  }
  @else if $point == 480 {
    @media (max-width: 480px)  { @content; }
  }
  @else if $point == 400 {
    @media (max-width: 400px)  { @content; }
  }
  @else if $point == 320 {
    @media (max-width: 320px)  { @content; }
  }
  @else if $point == retina {
    @media (min--moz-device-pixel-ratio: 1.5), (-o-min-device-pixel-ratio: 1.5/1), (-webkit-min-device-pixel-ratio: 1.5), (min-device-pixel-ratio: 1.5) { @content; }
  }
}
