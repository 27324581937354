.footer{
	background: #FFFFFF;
	box-shadow: 0 -1px 0 0 #E2E2E9;
	padding: 15px 0;

	position: fixed;
	width: 100%;
	height: 62px;
	bottom: 0;
	.order__status{
		small{
			color: #111111;
			span{
				color: #AFAFBD;
			}
		}
	}
	.order__action{
		.btn--primary{
			margin-left: 15px;
		}
	}
}