.ico-trashbin{
	rect,
	polyline,
	path{
		transition: .2s ease;
	}
	&:hover{
		rect{
			fill: #8E8E93;
		}
		polyline,
		path{
			stroke: #fff;
		}
	}
}

.ico-chevron-down{
	@include sprite(26px, 26px, 0);
	background-image: url(/images/ico/ico-chevron-down.svg);
	background-repeat: no-repeat;
	background-position: center;
}

.ico-chevrondown{
	circle,
	path{
		transition: .2s ease;
	}
	&:hover{
		circle{
			fill: #8E8E93;
		}
		path{
			fill: #fff;
		}
	}
}