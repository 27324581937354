.popup__wrapper{
	position: fixed;
	top:0;left:0;
	z-index: 10;
	background-color: rgba(0,0,0,.5);
	width:100%;height:100%;
	transition: opacity .3s ease;

	
	.popup__inner{
		display: flex;
		justify-content: center;
		align-items: center;
		width: 100%;height:100%;
	}
	.popup__content{
		width: 400px;
		background-color: #fff;
		border-radius: 12px;
		padding: 25px 20px;
		transition: .3s ease;
		h4{margin-bottom: 10px;}
		.popup__button{
			margin-top: 20px;
			.btn--primary,
			.btn--pasive{
				padding: 10px 30px 11px;
			}
			a{
				margin-left: 12px;
				&:first-child{
					margin-left: 0;
				}
			}
		}
	}
}


.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .popup__content,
.modal-leave-active .popup__content {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}