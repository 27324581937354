/* ############ Open Sans ############ */
@font-face {
    font-family: 'opensans-italic';
    src: url('/fonts/opensans/opensans-italic.eot');
    src: url('/fonts/opensans/opensans-italic.eot?#iefix') format('embedded-opentype'),
         url('/fonts/opensans/opensans-italic.woff') format('woff'),
         url('/fonts/opensans/opensans-italic.svg#opensans-italic') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'opensans-regular';
    src: url('/fonts/opensans/opensans-regular.eot');
    src: url('/fonts/opensans/opensans-regular.eot?#iefix') format('embedded-opentype'),
         url('/fonts/opensans/opensans-regular.woff') format('woff'),
         url('/fonts/opensans/opensans-regular.svg#opensans-regular') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'opensans-semibold';
    src: url('/fonts/opensans/opensans-semibold.eot');
    src: url('/fonts/opensans/opensans-semibold.eot?#iefix') format('embedded-opentype'),
         url('/fonts/opensans/opensans-semibold.woff') format('woff'),
         url('/fonts/opensans/opensans-semibold.svg#opensans-semibold') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'opensans-bold';
    src: url('/fonts/opensans/opensans-bold.eot');
    src: url('/fonts/opensans/opensans-bold.eot?#iefix') format('embedded-opentype'),
         url('/fonts/opensans/opensans-bold.woff') format('woff'),
         url('/fonts/opensans/opensans-bold.svg#opensans-bold') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'opensans-extrabold';
    src: url('/fonts/opensans/opensans-extrabold.eot');
    src: url('/fonts/opensans/opensans-extrabold.eot?#iefix') format('embedded-opentype'),
         url('/fonts/opensans/opensans-extrabold.woff') format('woff'),
         url('/fonts/opensans/opensans-extrabold.svg#opensans-extrabold') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'opensans-semibold_italic';
    src: url('/fonts/opensans/opensans-semibolditalic.eot');
    src: url('/fonts/opensans/opensans-semibolditalic.eot?#iefix') format('embedded-opentype'),
         url('/fonts/opensans/opensans-semibolditalic.woff') format('woff'),
         url('/fonts/opensans/opensans-semibolditalic.svg#opensans-semibold_italic') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'opensans-bold_italic';
    src: url('/fonts/opensans/opensans-bolditalic.eot');
    src: url('/fonts/opensans/opensans-bolditalic.eot?#iefix') format('embedded-opentype'),
         url('/fonts/opensans/opensans-bolditalic.woff') format('woff'),
         url('/fonts/opensans/opensans-bolditalic.svg#opensansbold_italic') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'opensans-extrabold_italic';
    src: url('/fonts/opensans/opensans-extrabolditalic.eot');
    src: url('/fonts/opensans/opensans-extrabolditalic.eot?#iefix') format('embedded-opentype'),
         url('/fonts/opensans/opensans-extrabolditalic.woff') format('woff'),
         url('/fonts/opensans/opensans-extrabolditalic.svg#opensans-extrabold_italic') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'opensans-light';
    src: url('/fonts/opensans/opensans-light.eot');
    src: url('/fonts/opensans/opensans-light.eot?#iefix') format('embedded-opentype'),
         url('/fonts/opensans/opensans-light.woff') format('woff'),
         url('/fonts/opensans/opensans-light.svg#opensans-light') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'opensans-light_italic';
    src: url('/fonts/opensans/opensans-lightitalic.eot');
    src: url('/fonts/opensans/opensans-lightitalic.eot?#iefix') format('embedded-opentype'),
         url('/fonts/opensans/opensans-lightitalic.woff') format('woff'),
         url('/fonts/opensans/opensans-lightitalic.svg#opensans-light_italic') format('svg');
    font-weight: normal;
    font-style: normal;
}