input[type="text"],
textarea{
	background: #EBEDF0;
	border: 1px solid #EBEDF0;
	border-radius: 4px;
	font-size: 12px;
	color: #555563;
	letter-spacing: 0;
	height: 28px;
	width: 100%;
	padding: 5px 8px;
	font-family: 'opensans-regular', sans-serif;
	outline:0;
	&:focus{
		border: 1px solid #4DBEEB;
	}
}
textarea{
	height: 70px;
	resize: none;
}

.chosen-container{
	.chosen-single{
		border: 0;
		box-shadow: none;
		background-image: none;
		background-color: #EBEDF0;
		border-radius: 4px;
		font-size: 12px;
		color: #555563;
		letter-spacing: 0;
		line-height: 30px;
		height: 28px;
		div{
			b{
				background: none;
				position: relative;
				&:before{
					content:'';
					width: 5px;
					height:5px;
					border-top: 1px solid #5B5B6F;
					border-left: 1px solid #5B5B6F;
					position: absolute;
					top:-1px;right:10px;bottom:0;left:0;
					margin:auto;
					transform: rotate(225deg);
				}
			}
		}
	}
	&.chosen-with-drop{
		.chosen-single{
			border:0;
			box-shadow: none;
			background-image: none;
			background-color: #5B5B6F;
			color: #fff;
			border-radius: 4px;
			div{
				b{
					&:before{
						border-top: 1px solid #fff;
						border-left: 1px solid #fff;
						top:2px;
						transform: rotate(45deg);
					}
				}
			}
		}
	}
	.chosen-drop{
		border-top: 1px;
		margin-top: 8px;
		background: #FFFFFF;
		border: 1px solid #E0E6ED;
		box-shadow: 0 2px 3px 0 rgba(0,0,0,0.12);
		border-radius: 3px;
		.chosen-search{
			padding: 7px;
			input[type="text"]{
				background-color: #FFFFFF;
				border-radius: 4px;
				margin:0;
				&:focus{
					border: 1px solid #4DBEEB;
				}
			}
		}
		.chosen-results{
			margin:0;
			padding:0;
			li{
				font-size: 12px;
				padding: 5px 7px;
				&.highlighted{
					background-image: none;
					background-color: #F5F7FA;
					color: #586780;
				}
			}
		}
	}	
}
.checkbox__container{
	.checkbox__wrapper{
		margin-bottom: 9px;
		&:last-child{
			margin-bottom: 0;
		}
	}
	.tag{
		margin-left: 10px;
	}
}
.checkbox{
	display: none;
	&+ label{
		font-size: 16px;
		color: #555563;
		letter-spacing: 0;
		padding-left: 30px;
		position: relative;
		cursor: pointer;
		&:before{
			content: '';
			width: 20px;
			height:20px;
			border-radius: 2px;
			box-shadow: 0 0 0 0px #87aebd inset;
			border: 1px solid #555563;
			position: absolute;
			left: 0;
			top: 2px;
			transition: all .2s ease-in-out;
		}
		&:after{
			content: '';
			background-image: url(/images/ico/ico-check.svg);
			background-repeat: no-repeat;
			background-position: center;
			width: 22px;
			height: 20px;
			position: absolute;
			left: 2px;
			transform: scale(0);
			opacity: 0;
		}
	}
	&:checked,
	&.checked{
		&+ label{
			&:before{
				border: none;
				box-shadow: 0 0 0 10px #87aebd inset;
			}
			&:after{
				transform: scale(1);
				opacity: 1;
				transition: all .2s ease-in-out .1s;
			}
		}
	}
	&.disabled,
	&:disabled{
		&+ label{
			pointer-events: none;
			cursor: default;
			&:before{opacity: .5;}
			&:after{background-image: url(/images/ico/ico-check-disabled.svg);}
		}
	}
}